export default {
  apiUrl: 'https://backend-learn.estate.hart-digital.com',
  apiBovwUrl: 'https://bovw.srv.getfloorplan.com',
  apiControlPanel: 'https://panel.hart-digital.com',
  appUrl: 'https://backoffice-learn.estate.hart-digital.com',
  publicUrl: 'https://backoffice-learn.estate.hart-digital.com/public_plans',
  appEnv: 'learn',
  widget: {
    logoUrlEn: 'https://getfloorplan.com/',
  },
  pusher: {
    appKey: 'c58c7bbf1f4039222764',
    appCluster: 'eu',
  },
  sentry: {
    environment: 'learn',
    dsn: 'https://5974d49ae13dc3adf46baab7f03bae18@o4505765114281984.ingest.sentry.io/4505884129296384',
  },
  featureFlagsOverride: {
    isEnvironmentVisible: true,
  },
  yandexMetrika: {
    counterId: 97097353,
    initOptions: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  },
  waitDuration: 60,
};
